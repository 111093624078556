import  Login from '../components/base/Login'

import { AgendarScreen } from '../components/agendar/Agendar'
import { SeguimientoScreen } from '../components/seguimiento/Seguimiento'
import { GestionScreen } from '../components/gestion/Gestion'

import { AgendarCambioScreen } from '../components/agendar-cambio/AgendarCambio'
import { SeguimientoCambioScreen } from '../components/seguimiento-cambio/SeguimientoCambio'
import { GestionCambioScreen } from '../components/gestion-cambio/GestionCambio'
import { TanquesScreen } from '../components/tanques/Tanques'
import { DespachosScreen } from '../components/despachos/Despachos'

const rutas = [
    {path: '/agendar', componente: AgendarScreen, permiso: 920, text: 'Agendar' },
    {path: '/seguimiento', componente: SeguimientoScreen, permiso: 921, text: 'Seguimiento' },
    {path: '/gestion', componente: GestionScreen, permiso: 922, text: 'Gestion' },

    {path: '/agendar-cambio', componente: AgendarCambioScreen, permiso: 923, text: 'Agendar Cambio' },
    {path: '/seguimiento-cambio', componente: SeguimientoCambioScreen, permiso: 924, text: 'Seguimiento Cambio' },
    {path: '/gestion-cambio', componente: GestionCambioScreen, permiso: 925, text: 'Gestion Cambio' },

    {path: '/tanques', componente: TanquesScreen, permiso: 926, text: 'Tanques' },
    {path: '/despachos', componente: DespachosScreen, permiso: 923, text: 'Despachos' },
    
    {path: '/login', componente: Login },
    {path: '', componente: Login },
    //{path: '*', componente: RedirectBase }
]

export default rutas