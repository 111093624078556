import React, { useState, useEffect, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getZonas } from '../../helpers/estanques/apiCalls'
import { GeneralContext } from '../../context/GeneralContext'
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));



export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [zonas, setZonas] = useState([])

    /*
    const [values, setValues] = useState({
        fechaDesde: moment(new Date()).format("YYYY-MM-DD"),
        fechaHasta: moment(new Date()).format("YYYY-MM-DD")
    })
    */

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true
        //setStateGeneral({ ...stateGeneral, filters: values })
        setOpenSpinner(true)
        //sessionStorage.setItem('fechaDesde', moment(new Date()).format("YYYY-MM-DD"))
       // sessionStorage.setItem('fechaHasta', moment(new Date()).format("YYYY-MM-DD"))
        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [])

    /*
    const handleChangeForm = (name, event) => {
        if (name === 'fechaDesde' || name === 'fechaHasta') {
            setValues({ ...values, [name]: event });
            setStateGeneral({ ...stateGeneral, [name]: event });
        } else {
            setValues({ ...values, [name]: event.target.value });
        }
    }
    */

    const onHandleSubmit = e => {
        e.preventDefault()
        //setStateGeneral({ ...stateGeneral, filtersExportSll_gestion: values })
        setReload(new Date())
    }

    const estados = [
        { id: 1, estado: 'Disponible'},
        { id: 2, estado: 'Agendado'},
        { id: 3, estado: 'Confirmado'},
        { id: 4, estado: 'Anulado'},
        { id: 5, estado: 'Archivo Generado'},
        { id: 6, estado: 'Cargado en ArcGig'},
        { id: 7, estado: 'Eliminado en ArcGis'},
        { id: 8, estado: 'Completado en ArcGis'},
        { id: 9, estado: 'Pendiente en ArcGis'},
        { id: 10, estado: 'Enviado a SAP'},
    ]

    return (
        <div className={classes.root}>
            <form onSubmit={onHandleSubmit}>
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={12}>
                        <Grid container justifycontent="center" spacing={8}>
                            <Grid item>
                                <TextField
                                    id="fechaDesde"
                                    label="Fecha Desde"
                                    type="date"
                                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                    className={classes.textField}
                                    onChange={(event, newValue) => {
                                        if (!isEmpty(event.target.value)) {
                                            sessionStorage.setItem('fechaDesdeCambio', moment(event.target.value).format("YYYY-MM-DD"))
                                        } else {
                                            sessionStorage.removeItem('fechaDesdeCambio')
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="fechaHasta"
                                    label="Fecha Hasta"
                                    type="date"
                                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                    className={classes.textField}
                                    onChange={(event, newValue) => {
                                        if (!isEmpty(event.target.value)) {
                                            sessionStorage.setItem('fechaHastaCambio', moment(event.target.value).format("YYYY-MM-DD"))
                                        } else {
                                            sessionStorage.removeItem('fechaHastaCambio')
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                {estados && estados.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={estados}
                                        getOptionLabel={(option) => `${option.id} - ${option.estado}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('estadoCambio');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = zonas.find(zona => zona.id == parsed.id);
                                                if (found) {
                                                    const index = zonas.indexOf(found);
                                                    return zonas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('estadoCambio', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('estadoCambio')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Estado OT" placeholder="Seleccione Estado OT" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item style={{ marginTop: 8 }}>
                                <ButtonControl
                                    label="Buscar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />
        </div>
    )
}


