import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getComunasAll } from '../../helpers/comuna/apiCalls'
import { getZonas, getCanales, getOrgVentas, getMonthYear } from '../../helpers/despachos/apiCalls'
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty, size } from 'lodash'
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [comunas, setComunas] = useState([])
    const [zonas, setZonas] = useState([])
    const [canales, setCanales] = useState([])
    const [orgVentas, setOrgVentas] = useState([])
    const [monthYear, setMonthYear] = useState([])
    const [tipo, setTipo] = useState([])
    const [frecuencia, setFrecuencia] = useState([])

    const [datosFaltantes, setDatosFaltantes] = useState([])
    const [checklock, setChecklock] = useState([])
    const [motivoVisita, setMotivoVisita] = useState([])

    const [mesDesde, setMesDesde] = useState()
    const [mesHasta, setMesHasta] = useState()

    const [porcentaje, setPorcentaje] = useState([25, 75]);
    const [inputValue, setInputValue] = useState(['25', '75']);

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true

        setOpenSpinner(true)
        getComunasAll()
            .then(({ comunas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setComunas(comunas)
                }
            })
        getZonas()
            .then(({ zonas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setZonas(zonas)
                }
            })
        getCanales()
            .then(({ canales }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setCanales(canales)
                }
            })
        getOrgVentas()
            .then(({ orgVentas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setOrgVentas(orgVentas)
                }
            })
        getMonthYear()
            .then(({ monthYear }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setMonthYear(monthYear)
                }
            })
        setTipo([
            { valor: 'SB', tipo: "Subterraneo Horizontal" },
            { valor: 'AE', tipo: "Superficie Horizontal" },
            { valor: 'VE', tipo: "Superficie Vertical" },
            { valor: 'AV', tipo: "Superficie Vertical" }
        ]);
        setFrecuencia([
            { valor: 3, tipo: "3 Meses" },
            { valor: 6, tipo: "6 Meses" },
            { valor: 12, tipo: "12 Meses" }
        ]);

        setDatosFaltantes([
            { valor: 1, tipo: "Falta dato" },
        ]);
        setChecklock([
            { valor: 1, tipo: "Si" },
            { valor: 2, tipo: "No" },
        ]);
        setMotivoVisita([
            { valor: 1, tipo: "Cambio" },
            { valor: 2, tipo: "Retiro" },
            { valor: 3, tipo: "Despacho" },
        ]);


        return () => { isMounted = false }
    }, [])

    const onHandleSubmit = e => {
        e.preventDefault()

        console.log(e)

        setReload(new Date())
    }

    const handleDateChangeDesde = (date) => {
        sessionStorage.setItem('mesDesdeCambio', JSON.stringify(moment(date).format("yyyy-MM")))
        setMesDesde(date);
    };

    const handleDateChangeHasta = (date) => {
        sessionStorage.setItem('mesHastaCambio', JSON.stringify(moment(date).format("yyyy-MM")))
        setMesHasta(date);
    };

    const handleChange = (event, newValue) => {
        setPorcentaje(newValue);
        setInputValue(newValue.map(String));
        sessionStorage.setItem('porcentaje', newValue)
    };

    const handleInputChange = (event, index) => {
        const newValue = [...inputValue];
        newValue[index] = event.target.value === '' ? '0' : event.target.value.trim();
        setInputValue(newValue);

        const newPorcentaje = newValue.map(parseFloat);
        setPorcentaje(newPorcentaje);
        sessionStorage.setItem('porcentaje', newPorcentaje)
    };

    const valuetext = (value) => {
        return `${value[0]}% - ${value[1]}%`;
    };

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 25,
            label: '25%',
        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 75,
            label: '75%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    return (
        <div className={classes.root}>
            <form onSubmit={onHandleSubmit}>
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={12}>
                        <Grid container justifycontent="center" spacing={8}>
                            <Grid item>
                                {comunas && comunas.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={comunas}
                                        getOptionLabel={(option) => `${option.c_comuna} - ${option.comunaNombre}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('comuna');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = tipo.find(motivoVisita => motivoVisita.valor == parsed.valor);
                                                if (found) {
                                                    const index = tipo.indexOf(found);
                                                    return motivoVisita[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('comuna', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('comuna')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Comuna" placeholder="Seleccione comuna" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        variant="inline"
                                        label="Mes Solicitud Desde"
                                        value={sessionStorage.getItem('mesDesdeSolicitud')}//{mesDesde}
                                        onChange={handleDateChangeDesde}
                                        className={classes.formControl}
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy-MM"
                                        inputProps={{ placeholder: 'Seleccione Mes Solicitud Desde' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        variant="inline"
                                        label="Mes Solicitud Hasta"
                                        value={sessionStorage.getItem('mesHastaSolicitud')}
                                        onChange={handleDateChangeHasta}
                                        className={classes.formControl}
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy-MM"
                                        inputProps={{ placeholder: 'Seleccione Mes Solicitud Hasta' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item style={{ marginTop: 5, marginLeft: 23 }}>
                                <ButtonControl
                                    label="Buscar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />
        </div>
    )
}