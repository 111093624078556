import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonControl } from '../base/ButonControl'
import Grid from '@material-ui/core/Grid'
import { InputText } from '../base/controls/InputText'
import { login } from '../../helpers/auth/apiCalls'
import { guardarTokenLocalStorage } from '../../helpers/util'
import { isEmpty } from 'lodash'
import rutas from '../../helpers/rutas'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const Login = () => {
    const classes = useStyles()
    const [disabledButton, setDisabledButton] = useState(false)
    const [errorLogin, setErrorLogin] = useState(false)
    const [values, setValues] = useState({})
    const handleChangeForm = (name, event) => {
        setValues({ ...values, [name]: event.target.value }) //.toUpperCase().trim()
    }
    const onHandleSubmit = e => {
        setErrorLogin(false)
        setDisabledButton(true)
        e.preventDefault()

        login(values)
            .then(response => {
                if (isEmpty(response.token)) {
                    setErrorLogin(true)
                    setDisabledButton(false)
                    return
                }
                setDisabledButton(false)
                guardarTokenLocalStorage(response);

                const permisosUser = response.permisos

                if(permisosUser !== null) {
                    let i = 0;
                    while (i < rutas.length) {
                        if (permisosUser.includes(rutas[i].permiso)) {
                            window.location = rutas[i].path
                            localStorage.setItem('selectedMenu', rutas[i].text)
                            break;
                        }
                        i = i + 1;
                    }
                } else {
                    window.location = '/login'
                }
            })
    }
    return (
        <div className={classes.root}>
            <form onSubmit={onHandleSubmit}>
                <Grid
                    container
                    direction="column"
                    justifycontent="center"
                    alignItems="center"
                >
                    <Grid
                        container item xs={3}
                        spacing={3}
                        direction="column"
                        alignItems="center"
                        style={{ paddingTop: 50 }
                        }>
                        <img src="/Lipigas_color_horizontal.svg" alt="logo" style={{ width: '250px' }} />
                    </Grid>
                    <Grid
                        container item xs={3}
                        spacing={3}
                        direction="column"
                        alignItems="center"
                        style={{ padding: 50 }
                        }>
                        <Typography variant="h5" noWrap style={{ fontWeight: 600, fontFamily: '"Poppins", sans-serif' }}>
                            Mantención de Tanques
                        </Typography>
                    </Grid>
                    <Grid
                        container item xs={2}
                        spacing={3}
                        direction="column"
                        alignItems="center"
                    >
                        <InputText
                            name="usuario"
                            label="Usuario"
                            required={true}
                            type="text"
                            value={values.usuario}
                            onChangeFunction={(e) => handleChangeForm("usuario", e)}
                            lengthMax={100}
                        />
                        <InputText
                            name="password"
                            label="Password"
                            required={true}
                            type="password"
                            value={values.password}
                            onChangeFunction={(e) => handleChangeForm("password", e)}
                            lengthMax={100}
                        />
                    </Grid>
                    <Grid
                        container item xs={3}
                        spacing={3}
                        direction="column"
                        alignItems="center"
                        style={{ padding: 50 }}
                    >
                        <ButtonControl
                            label="Login"
                            type="primary"
                            disabled={disabledButton}
                        />
                    </Grid>
                    {errorLogin &&
                        <Grid
                            container item xs={3}
                            spacing={3}
                            direction="column"
                            alignItems="center"
                            style={{
                                padding: 4,
                                borderStyle: 'groove',
                                width: 300,
                                height: 1,
                                borderRadius: 10,
                                color: 'red'
                            }}
                        >
                            <Typography variant="h6" noWrap style={{
                                fontWeight: 600,
                                fontFamily: 'system-ui',
                                fontSize: 'smaller'
                            }}>
                                Credenciales incorrectas...
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </form>
        </div>

    )
}

export default Login
