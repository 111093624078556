import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getComunasAll } from '../../helpers/comuna/apiCalls'
import { getZonas, getCanales, getOrgVentas, getMonthYear } from '../../helpers/cambio/apiCalls'
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty, size } from 'lodash'
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [comunas, setComunas] = useState([])
    const [zonas, setZonas] = useState([])
    const [canales, setCanales] = useState([])
    const [orgVentas, setOrgVentas] = useState([])
    const [monthYear, setMonthYear] = useState([])
    const [tipo, setTipo] = useState([])
    const [frecuencia, setFrecuencia] = useState([])

    const [datosFaltantes, setDatosFaltantes] = useState([])
    const [datosResultadoVisita, setResultadoVisita] = useState([])
    const [checklock, setChecklock] = useState([])
    const [motivoVisita, setMotivoVisita] = useState([])

    const [mesDesde, setMesDesde] = useState()
    const [mesHasta, setMesHasta] = useState()

    const [porcentaje, setPorcentaje] = useState([25, 75]);
    const [inputValue, setInputValue] = useState(['25', '75']);

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true

        setOpenSpinner(true)
        getComunasAll()
            .then(({ comunas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setComunas(comunas)
                }
            })
        getZonas()
            .then(({ zonas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setZonas(zonas)
                }
            })
        getCanales()
            .then(({ canales }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setCanales(canales)
                }
            })
        getOrgVentas()
            .then(({ orgVentas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setOrgVentas(orgVentas)
                }
            })
        getMonthYear()
            .then(({ monthYear }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setMonthYear(monthYear)
                }
            })
        setTipo([
            { valor: '5001', tipo: "1.0 M3 Horizontal Subterraneo" },
            { valor: '5002', tipo: "0.5 M3 Horizontal Superficie" },
            { valor: '5003', tipo: "0.5 M3 Horizontal Subterráneo" },
            { valor: '5005', tipo: "1.0 M3 Horizontal Superficie" },
            { valor: '5006', tipo: "2.0 M3 Horizontal Superficie" },
            { valor: '5007', tipo: "2.0 M3 Horizontal Subterraneo" },
            { valor: '5008', tipo: "4.0 M3 Horizontal Superficie" },
            { valor: '5009', tipo: "4.0 M3 Horizontal Subterraneo" },
            { valor: '5021', tipo: "0.5 M3 Vertical Superficie" },
            { valor: '5022', tipo: "0.5 M3 Vertical Subterraneo" },
            { valor: '5023', tipo: "Tanque grúa 50 lts" },
            { valor: '5026', tipo: "Tanque grúa 60 lts" },
            { valor: '5073', tipo: "0.3 M3 Vertical Superficie" },
            { valor: '5088', tipo: "7.5 M3 Horizontal Superficie" },
            { valor: '5100', tipo: "4.0 M3 Vertical Superficie" },
        ]);

        setFrecuencia([
            { valor: 3, tipo: "3 Meses" },
            { valor: 6, tipo: "6 Meses" },
            { valor: 12, tipo: "12 Meses" }
        ]);

        setDatosFaltantes([
            { valor: 1, tipo: "Falta dato" },
        ]);

        setResultadoVisita([
            { valor: 'Programar Reinspección-Sin problemas', tipo: "Programar Reinspección-Sin problemas" },
            { valor: 'Programar Reinspección fase liquida', tipo: "Programar Reinspección fase liquida" },
            { valor: 'Programar Reinspección surtidor', tipo: "Programar Reinspección surtidor" },
            { valor: 'Programar Reinspección grúa especial', tipo: "Programar Reinspección grúa especial" },
            { valor: 'Programar Reinspección tecle y/o pulso', tipo: "Programar Reinspección tecle y/o pulso" },
            { valor: 'Programar reinspección con modificación por cliente simple', tipo: "Programar reinspección con modificación por cliente simple" },
            { valor: 'Programar reinspección con modificación por cliente complejo', tipo: "Programar reinspección con modificación por cliente complejo" },
            { valor: 'Programar Reinspección con modificación por LIPIGAS', tipo: "Programar Reinspección con modificación por LIPIGAS" },
            { valor: 'Programar Reinspección con desplazamiento menor 3 mt', tipo: "Programar Reinspección con desplazamiento menor 3 mt" },
            { valor: 'Programar Reinspección Pendiente Permiso Municipal', tipo: "Programar Reinspección Pendiente Permiso Municipal" },
            { valor: 'Sin contacto con cliente', tipo: "Sin contacto con cliente" },
            { valor: 'Cliente no permite cambio', tipo: "Cliente no permite cambio" },
            { valor: 'Cubicación > 3mt (Comercial)', tipo: "Cubicación > 3mt (Comercial)" },
            { valor: 'Cliente sin consumo más de 18 meses - Fidelizar', tipo: "Cliente sin consumo más de 18 meses - Fidelizar" },
            { valor: 'Cliente acepta el cambio, pero se niega a normalizar', tipo: "Cliente acepta el cambio, pero se niega a normalizar" },
            { valor: 'Derivar a Comercial - Dos visitas coordinadas sin éxito', tipo: "Derivar a Comercial - Dos visitas coordinadas sin éxito" },
            { valor: 'Fuera de norma derivar a SIM (Subir al portal)', tipo: "Fuera de norma derivar a SIM (Subir al portal)" },
            { valor: 'Programar segunda visita - Postergada por cliente', tipo: "Programar segunda visita - Postergada por cliente" },
            { valor: 'Programar segunda visita - Validar maniobra', tipo: "Programar segunda visita - Validar maniobra" },
            { valor: 'Actualización', tipo: "Actualización" },
            { valor: 'Retiro', tipo: "Retiro" },
            { valor: 'Pendiente acreditación', tipo: "Pendiente acreditación" },
            { valor: 'Sin Tanque en terreno', tipo: "Sin Tanque en terreno" },
            { valor: 'Tanque Sin Check Lock', tipo: "Tanque Sin Check Lock" },
        ]);

        setChecklock([
            { valor: 1, tipo: "Si" },
            { valor: 2, tipo: "No" },
        ]);
        setMotivoVisita([
            { valor: 1, tipo: "Cambio Plan" },
            { valor: 2, tipo: "Cambio Ticket" },
            { valor: 3, tipo: "Cambio reloj pegado / COE" },
            { valor: 4, tipo: "Cambio por fuga" },
            { valor: 5, tipo: "Retiro plan" },
            { valor: 6, tipo: "Retiro Ticket" },
        ]);

        if(!sessionStorage.getItem('mesDesdeCambio')){
            sessionStorage.setItem('mesDesdeCambio', JSON.stringify(moment(new Date()).format("yyyy-MM")))
        }

        if(!sessionStorage.getItem('mesHastaCambio')){
            sessionStorage.setItem('mesHastaCambio', JSON.stringify(moment(new Date()).format("yyyy-MM")))
        }

        return () => { isMounted = false }
    }, [])

    const onHandleSubmit = e => {
        e.preventDefault()

        console.log(e)

        setReload(new Date())
    }

    const handleDateChangeDesde = (date) => {
        sessionStorage.setItem('mesDesdeCambio', JSON.stringify(moment(date).format("yyyy-MM")))
        setMesDesde(date);
    };

    const handleDateChangeHasta = (date) => {
        sessionStorage.setItem('mesHastaCambio', JSON.stringify(moment(date).format("yyyy-MM")))
        setMesHasta(date);
    };

    const handleChange = (event, newValue) => {
        setPorcentaje(newValue);
        setInputValue(newValue.map(String));
        sessionStorage.setItem('porcentaje', newValue)
    };

    const handleInputChange = (event, index) => {
        const newValue = [...inputValue];
        newValue[index] = event.target.value === '' ? '0' : event.target.value.trim();
        setInputValue(newValue);

        const newPorcentaje = newValue.map(parseFloat);
        setPorcentaje(newPorcentaje);
        sessionStorage.setItem('porcentaje', newPorcentaje)
    };

    const valuetext = (value) => {
        return `${value[0]}% - ${value[1]}%`;
    };

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 25,
            label: '25%',
        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 75,
            label: '75%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    return (
        <div className={classes.root}>
            <form onSubmit={onHandleSubmit}>
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={12}>
                        <Grid container justifycontent="center" spacing={8}>
                            <Grid item>
                                {datosResultadoVisita && datosResultadoVisita.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={datosResultadoVisita}
                                        getOptionLabel={(option) => `${option.tipo}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('resultadoVisita');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = datosResultadoVisita.find(resultadoVisita => resultadoVisita.valor == parsed.valor);
                                                if (found) {
                                                    const index = datosResultadoVisita.indexOf(found);
                                                    return datosResultadoVisita[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('resultadoVisita', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('resultadoVisita')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Resultado Visita" placeholder="Seleccione Resultado Visita" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {datosFaltantes && datosFaltantes.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={datosFaltantes}
                                        getOptionLabel={(option) => `${option.tipo}`}
                                        /*
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('zonaAgendarCambio');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = zonas.find(zona => zona.id_zona == parsed.id_zona);
                                                if (found) {
                                                    const index = zonas.indexOf(found);
                                                    return zonas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('zonaAgendarCambio', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('zonaAgendarCambio')
                                            }
                                        }}
                                            */
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Fecha de quiebre desde" placeholder="Seleccione Fecha de quiebre desde" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {datosFaltantes && datosFaltantes.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={datosFaltantes}
                                        getOptionLabel={(option) => `${option.tipo}`}
                                        /*
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('zonaAgendarCambio');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = zonas.find(zona => zona.id_zona == parsed.id_zona);
                                                if (found) {
                                                    const index = zonas.indexOf(found);
                                                    return zonas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('zonaAgendarCambio', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('zonaAgendarCambio')
                                            }
                                        }}
                                            */
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Fecha de quiebre hasta" placeholder="Seleccione Fecha de quiebre hasta" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        variant="inline"
                                        label="Mes Vencimiento Desde"
                                        value={sessionStorage.getItem('mesDesdeCambio')}//{mesDesde}
                                        onChange={handleDateChangeDesde}
                                        className={classes.formControl}
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy-MM"
                                        inputProps={{ placeholder: 'Seleccione Mes Vencimiento Desde' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        variant="inline"
                                        label="Mes Vencimiento Hasta"
                                        value={sessionStorage.getItem('mesHastaCambio')}
                                        onChange={handleDateChangeHasta}
                                        className={classes.formControl}
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy-MM"
                                        inputProps={{ placeholder: 'Seleccione Mes Vencimiento Hasta' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item>
                                {orgVentas && orgVentas.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={orgVentas}
                                        getOptionLabel={(option) => `${option.orgvta} - ${option.orgvta_descripcion}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('orgVentasCambio');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = orgVentas.find(canal => canal.orgvta == parsed.orgvta);
                                                if (found) {
                                                    const index = orgVentas.indexOf(found);
                                                    return orgVentas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('orgVentasCambio', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('orgVentasCambio')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Organización Ventas" placeholder="Seleccione Organización Ventas" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {tipo && tipo.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={tipo}
                                        getOptionLabel={(option) => `${option.valor} - ${option.tipo}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('tipoTanqueCambio');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = tipo.find(tipo => tipo.valor == parsed.valor);
                                                if (found) {
                                                    const index = tipo.indexOf(found);
                                                    return tipo[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('tipoTanqueCambio', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('tipoTanqueCambio')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Material - Formato" placeholder="Seleccione Material - Formato" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {canales && canales.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={canales}
                                        getOptionLabel={(option) => `${option.can_codigo} - ${option.can_descripcion}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('canalCambio');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = canales.find(canal => canal.can_codigo == parsed.can_codigo);
                                                if (found) {
                                                    const index = canales.indexOf(found);
                                                    return canales[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('canalCambio', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('canalCambio')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Canal" placeholder="Seleccione Canal" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {checklock && checklock.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={checklock}
                                        getOptionLabel={(option) => `${option.tipo}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('checklock');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = frecuencia.find(checklock => checklock.valor == parsed.valor);
                                                if (found) {
                                                    const index = frecuencia.indexOf(found);
                                                    return checklock[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('checklock', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('checklock')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label=" Válula Check Lock" placeholder="Seleccione Válula Check Lock" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {motivoVisita && motivoVisita.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={motivoVisita}
                                        getOptionLabel={(option) => `${option.valor} - ${option.tipo}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('motivoVisita');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = tipo.find(motivoVisita => motivoVisita.valor == parsed.valor);
                                                if (found) {
                                                    const index = tipo.indexOf(found);
                                                    return motivoVisita[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('motivoVisita', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('motivoVisita')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Motivo de visita" placeholder="Seleccione Motivo de visita" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {comunas && comunas.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={comunas}
                                        getOptionLabel={(option) => `${option.c_comuna} - ${option.comunaNombre}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('comuna');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = tipo.find(motivoVisita => motivoVisita.valor == parsed.valor);
                                                if (found) {
                                                    const index = tipo.indexOf(found);
                                                    return motivoVisita[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('comuna', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('comuna')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Comuna" placeholder="Seleccione comuna" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item style={{ marginLeft: -12 }}>
                                <Grid item style={{ marginLeft: -10 }}>
                                    <TextField
                                        variant="outlined"
                                        label="Porcentaje de Gas Desde"
                                        value={inputValue[0]}
                                        onChange={(e) => handleInputChange(e, 0)}
                                        inputProps={{ style: { textAlign: 'center', width: 180 } }}
                                        size='small'
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Hasta"
                                        value={inputValue[1]}
                                        onChange={(e) => handleInputChange(e, 1)}
                                        inputProps={{ style: { textAlign: 'center', width: 180 } }}
                                        size='small'
                                    />
                                </Grid>
                                <Grid item style={{ width: 400 }}>
                                    <Slider
                                        value={porcentaje}
                                        onChange={handleChange}
                                        marks={marks}
                                        aria-labelledby="range-slider"
                                        min={0}
                                        max={100}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginTop: 5, marginLeft: 23 }}>
                                <ButtonControl
                                    label="Buscar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />
        </div>
    )
}