import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getComunasAll } from '../../helpers/comuna/apiCalls'
import { getZonas, getCanales, getOrgVentas, getMonthYear } from '../../helpers/estanques/apiCalls'
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [comunas, setComunas] = useState([])
    const [zonas, setZonas] = useState([])
    const [canales, setCanales] = useState([])
    const [orgVentas, setOrgVentas] = useState([])
    const [monthYear, setMonthYear] = useState([])
    const [tipo, setTipo] = useState([])
    const [frecuencia, setFrecuencia] = useState([])

    const [mesDesde, setMesDesde] = useState()
    const [mesHasta, setMesHasta] = useState()

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true

        setOpenSpinner(true)
        getComunasAll()
            .then(({ comunas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setComunas(comunas)
                }
            })
        getZonas()
            .then(({ zonas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setZonas(zonas)
                }
            })
        getCanales()
            .then(({ canales }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setCanales(canales)
                }
            })
        getOrgVentas()
            .then(({ orgVentas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setOrgVentas(orgVentas)
                }
            })
        getMonthYear()
            .then(({ monthYear }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setMonthYear(monthYear)
                }
            })
        setTipo([
            { valor: 'SB', tipo: "Subterraneo Horizontal" },
            { valor: 'AE', tipo: "Superficie Horizontal" },
            { valor: 'VE', tipo: "Superficie Vertical" },
            { valor: 'AV', tipo: "Superficie Vertical" }
        ]);
        setFrecuencia([
            { valor: 3, tipo: "3 Meses" },
            { valor: 6, tipo: "6 Meses" },
            { valor: 12, tipo: "12 Meses" }
        ]);

        if(!sessionStorage.getItem('mesDesde')){
            sessionStorage.setItem('mesDesde', JSON.stringify(moment(new Date()).format("yyyy-MM")))
        }

        if(!sessionStorage.getItem('mesHasta')){
            sessionStorage.setItem('mesHasta', JSON.stringify(moment(new Date()).format("yyyy-MM")))
        }

        return () => { isMounted = false }
    }, [])

    const onHandleSubmit = e => {
        e.preventDefault()
        setReload(new Date())
    }

    const handleDateChangeDesde = (date) => {
        sessionStorage.setItem('mesDesde', JSON.stringify(moment(date).format("yyyy-MM")))
        setMesDesde(date);
    };

    const handleDateChangeHasta = (date) => {
        sessionStorage.setItem('mesHasta', JSON.stringify(moment(date).format("yyyy-MM")))
        setMesHasta(date);
    };

    return (
        <div className={classes.root}>
            <form onSubmit={onHandleSubmit}>
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={12}>
                        <Grid container justifycontent="center" spacing={8}>
                            <Grid item>
                                {zonas && zonas.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={zonas}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('zonaAgendar');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = zonas.find(zona => zona.id_zona == parsed.id_zona);
                                                if (found) {
                                                    const index = zonas.indexOf(found);
                                                    return zonas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('zonaAgendar', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('zonaAgendar')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Técnico" placeholder="Seleccione Técnico" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {canales && canales.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={canales}
                                        getOptionLabel={(option) => `${option.can_codigo} - ${option.can_descripcion}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('canal');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = canales.find(canal => canal.can_codigo == parsed.can_codigo);
                                                if (found) {
                                                    const index = canales.indexOf(found);
                                                    return canales[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('canal', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('canal')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Canal" placeholder="Seleccione Canal" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {orgVentas && orgVentas.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={orgVentas}
                                        getOptionLabel={(option) => `${option.orgvta} - ${option.orgvta_descripcion}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('orgVentas');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = orgVentas.find(canal => canal.orgvta == parsed.orgvta);
                                                if (found) {
                                                    const index = orgVentas.indexOf(found);
                                                    return orgVentas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('orgVentas', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('orgVentas')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Organización Ventas" placeholder="Seleccione Organización Ventas" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        variant="inline"
                                        label="Mes Vencimiento Desde"
                                        value={sessionStorage.getItem('mesDesde')}//{mesDesde}
                                        onChange={handleDateChangeDesde}
                                        className={classes.formControl}
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy-MM"
                                        inputProps={{ placeholder: 'Seleccione Mes Vencimiento Desde' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        views={['year', 'month']}
                                        variant="inline"
                                        label="Mes Vencimiento Hasta"
                                        value={sessionStorage.getItem('mesHasta')}//{mesHasta}
                                        onChange={handleDateChangeHasta}
                                        className={classes.formControl}
                                        inputVariant="outlined"
                                        size="small"
                                        format="yyyy-MM"
                                        inputProps={{ placeholder: 'Seleccione Mes Vencimiento Hasta' }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                {frecuencia && frecuencia.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={frecuencia}
                                        getOptionLabel={(option) => `${option.tipo}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('frecuencia');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = frecuencia.find(frecuencia => frecuencia.valor == parsed.valor);
                                                if (found) {
                                                    const index = frecuencia.indexOf(found);
                                                    return frecuencia[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('frecuencia', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('frecuencia')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Frecuencia" placeholder="Seleccione Frecuencia" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                {tipo && tipo.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={tipo}
                                        getOptionLabel={(option) => `${option.valor} - ${option.tipo}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('tipo');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = tipo.find(canal => canal.valor == parsed.valor);
                                                if (found) {
                                                    const index = tipo.indexOf(found);
                                                    return tipo[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('tipo', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('tipo')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Tipo Tanque" placeholder="Seleccione Tipo Tanque" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item style={{ marginTop: 8 }}>
                                <ButtonControl
                                    label="Buscar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />
        </div>
    )
}