const apiEstanquesUrl = process.env.REACT_APP_API_ESTANQUES_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')


const token = localStorage.getItem('token');

export const getTanques = async (page, pageSize) => {
	const url = `${apiEstanquesUrl}/estanque`;

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders,
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json();

			return {
				rows: data.data,
				columns: columnsAgenda2,
				status: 'OK',
			};
		} else {
			const { errors } = await resp.json();
			return {
				status: 'NOK',
				message: errors[0].msg,
			};
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los tanques`,
		};
	}
};


const columnsAgenda2 = [
	{ field: 'codigo_item', headerName: 'codigo_item', width: 200 },
	{ field: 'id_tanque', headerName: 'id_tanque', width: 200 },
	{ field: 'capacidad', headerName: 'capacidad', width: 200 },
	{ field: 'capacidad_gl', headerName: 'capacidad_gl', width: 200 },
	{ field: 'unidad_medida', headerName: 'unidad_medida', width: 200 },
	{ field: 'tipo_estanque', headerName: 'tipo_estanque', width: 200 },
	{ field: 'dm', headerName: 'dm', width: 200 },
	{ field: 'codigo_instalacion', headerName: 'codigo_instalacion', width: 200 },
	{ field: 'codigo_inventario', headerName: 'codigo_inventario', width: 200 },
	{ field: 'fecha_fabricacion', headerName: 'fecha_fabricacion', width: 200 },
	{ field: 'fecha_ultima_inspeccion', headerName: 'fecha_ultima_inspeccion', width: 200 },
	{ field: 'fecha_instalacion', headerName: 'fecha_instalacion', width: 200 },
	{ field: 'fecha_mantenimiento', headerName: 'fecha_mantenimiento', width: 200 },
	{ field: 'centro', headerName: 'centro', width: 200 },
	{ field: 'serie', headerName: 'serie', width: 200 },
	{ field: 'numero_equipo', headerName: 'numero_equipo', width: 200 },
	{ field: 'estado', headerName: 'estado', width: 200 },
	{ field: 'inyectos_3_meses', headerName: 'inyectos_3_meses', width: 200 },
	{ field: 'inyectos_6_meses', headerName: 'inyectos_6_meses', width: 200 },
	{ field: 'inyectos_12_meses', headerName: 'inyectos_12_meses', width: 200 },
	{ field: 'fecha_ultimo_inyecto', headerName: 'fecha_ultimo_inyecto', width: 200 },
	{ field: 'ot', headerName: 'ot', width: 200 },
	{ field: 'ultima_fecha_stock', headerName: 'ultima_fecha_stock', width: 200 },
	{ field: 'contador', headerName: 'contador', width: 200 },
	{ field: 'mes_vencimiento', headerName: 'mes_vencimiento', width: 200 },
]