import React from 'react'
import Typography from '@material-ui/core/Typography';


export const TablaDeStock = ({ programados, centros }) => {
    return (
        <div style={{border: "1px solid black", paddingLeft: 10, paddingRight: 20}}>
            <Typography
                style={{ paddingLeft: 10, color: '#000000' }}
            >
                STOCK PROGRAMADOS: {programados}
            </Typography>
            <Typography
                style={{ paddingLeft: 10, color: '#000000' }}
            >
                STOCK CENTROS TOTAL: {centros}
            </Typography>
        </div>
    )
}