import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Spinner from '../base/Spinner'
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const [openSpinner, setOpenSpinner] = useState(false)

    useEffect(() => {
        let isMounted = true
        setOpenSpinner(true)
        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [])

    return (
        <div className={classes.root}>
            <Spinner open={openSpinner} />
        </div>
    )
}


