import React, { useState, createContext } from 'react'

export const GeneralContext = createContext()

export const GeneralProvider = ({ children }) => {
    const [stateGeneral, setStateGeneral] = useState({
    })
    return (
        <GeneralContext.Provider value={{
            stateGeneral,
            setStateGeneral
        }}>
            { children }
        </GeneralContext.Provider>
    )
}