import { isEmpty } from 'lodash'
const apiEstanquesUrl = process.env.REACT_APP_API_ESTANQUES_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')


const token = localStorage.getItem('token');

export const getEstanquesByFilters = async (zona, canal, orgVentas, mesDesde, mesHasta, frecuencia, tipo_estanque) => {
	const url = `${apiEstanquesUrl}/cliente`

	const raw = {
		"zona": isEmpty(zona) ? null : zona,
		"canal": isEmpty(canal) ? null : canal,
		"orgVentas": isEmpty(orgVentas) ? null : orgVentas,
		"mesDesde": isEmpty(mesDesde) ? null : mesDesde,
		"mesHasta": isEmpty(mesHasta) ? null : mesHasta,
		"frecuencia": isEmpty(frecuencia) ? null : frecuencia,
		"tipo_estanque": isEmpty(tipo_estanque) ? null : tipo_estanque
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsEstanques,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsEstanques = [
	//{ field: 'distancia', headerName: 'Distancia', width: 140, align: 'right' },
	{ field: 'dm', headerName: 'DM', width: 200 },
	{ field: 'ot', headerName: 'OT', width: 150 },
	{ field: 'estado_ot', headerName: 'Estado OT', width: 150 },
	{ field: 'canal', headerName: 'Canal', width: 200 },
	{ field: 'orgvta_vkorg', headerName: 'Org. Ventas', width: 160 },
	{ field: 'razon_social', headerName: 'Nombre Cliente', width: 300 },
	{ field: 'telefono', headerName: 'Teléfono', width: 140 },
	{ field: 'telefono2', headerName: 'Teléfono 2', width: 150 },
	{ field: 'comuna', headerName: 'Comuna', width: 200 },
	{ field: 'calle', headerName: 'Calle', width: 300 },
	{ field: 'numero', headerName: 'Número', width: 140 },
	{ field: 'referencia', headerName: 'Referencia', width: 500 },
	{ field: 'otros', headerName: 'Otros', width: 300 },
	{ field: 'emailc2', headerName: 'Email', width: 350 },
	{ field: 'codigo_instalacion', headerName: 'Cod. Instalación', width: 190 },
	{ field: 'codigo_tanque', headerName: 'Cod. Tanque', width: 180 },
	{ field: 'numero_serie', headerName: 'Num. Serie', width: 160 },
	{ field: 'tipo_estanque', headerName: 'Tipo Tanque', width: 170 },
	{ field: 'capacidad_tanque', headerName: 'Capacidad Tanque', width: 220 },
	{ field: 'estado_estanque', headerName: 'Estado Tanque', width: 190 },
	{ field: 'fecha_fabricacion', headerName: 'Fecha Fabricación', width: 210 },
	{ field: 'fecha_ultima_inspeccion', headerName: 'Fecha Ult. Inspección', width: 230 },
	{ field: 'fecha_instalacion', headerName: 'Fecha Instalación', width: 200 },
	{ field: 'equipo', headerName: 'Equipo', width: 190 },
	{ field: 'inyectos_3_meses', headerName: 'Inyectos 3 meses', width: 200 },
	{ field: 'inyectos_6_meses', headerName: 'Inyectos 6 meses', width: 200 },
	{ field: 'inyectos_12_meses', headerName: 'Inyectos 12 meses', width: 210 },
	{ field: 'fecha_ultimo_inyecto', headerName: 'Fecha último inyecto', width: 230 },
	{ field: 'mes_vencimiento', headerName: 'Mes Vencimiento', width: 200 },
	{ field: 'fecha_ultimo_contacto', headerName: 'Fecha último contacto', width: 240 },
	{ field: 'cli_latitud', headerName: 'Latitud', width: 150 },
	{ field: 'cli_longitud', headerName: 'Longitud', width: 150 },
	{ field: 'id_zona', headerName: 'Zona', width: 150 },
	{ field: 'cliente', headerName: 'Cliente', width: 150 },
	{ field: 'cod_sap_sol', headerName: 'Solicitante', width: 200 },
]

export const getZonas = async (values) => {
	const url = `${apiEstanquesUrl}/zona`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				zonas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las zonas`
		}
	}
}

export const getCanales = async (values) => {
	const url = `${apiEstanquesUrl}/cliente/canales`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				canales: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los canales`
		}
	}
}

export const getOrgVentas = async (values) => {
	const url = `${apiEstanquesUrl}/cliente/orgVentas`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				orgVentas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las OrgVentas`
		}
	}
}

export const getEstados = async (values) => {
	const url = `${apiEstanquesUrl}/estado`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				estados: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los estados`
		}
	}
}

export const getAcciones = async (tipo) => {
	const url = `${apiEstanquesUrl}/accion`

	const raw = {
		"tipo": tipo
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				acciones: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las acciones`
		}
	}
}

export const getMonthYear = async (values) => {
	const url = `${apiEstanquesUrl}/cliente/monthYear`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				monthYear: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los meses y años`
		}
	}
}

export const addAgenda = async (values) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiEstanquesUrl}/agenda/addAgenda`

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(values),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const respuesta = await resp.json()

			if (respuesta.cod_error === 9000) {
				return {
					message: 'Guardado exitosamente',
					status: 'OK'
				}
			} else {
				return {
					message: 'No se pudo guardar',
					status: 'NOK'
				}
			}

		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para guardar`
		}
	}
}


export const getAgenda = async (zona, fecha) => {
	const url = `${apiEstanquesUrl}/agenda`

	const body = {
		zona: zona,
		fecha: fecha
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsAgenda,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsAgenda = [
	{ field: 'hora', headerName: 'Hora', width: 150, headerAlign: 'right', align: 'right' },
	{ field: 'descripcion', headerName: 'Estado', width: 150, headerAlign: 'right', align: 'right' },
	{ field: 'distancia', headerName: 'Distancia (km)', width: 180, headerAlign: 'right', align: 'right' },
	{ field: 'ot', headerName: 'OT', width: 120, headerAlign: 'right', align: 'right' },
	{ field: 'dm', headerName: 'DM', width: 120, headerAlign: 'right', align: 'right' },
	{ field: 'orgvta_vkorg', headerName: 'Org. Ventas', width: 160, headerAlign: 'right', align: 'right' },
	{ field: 'codigo_tanque', headerName: 'Cod. Tanque', width: 180, headerAlign: 'right', align: 'right' },
	{ field: 'telefono', headerName: 'Teléfono', width: 140, headerAlign: 'right', align: 'right' },
	{ field: 'telefono2', headerName: 'Teléfono 2', width: 150, headerAlign: 'right', align: 'right' },
	{ field: 'razon_social', headerName: 'Nombre Cliente', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'calle', headerName: 'Calle', width: 300, headerAlign: 'right', align: 'right' },
	{ field: 'numero', headerName: 'Número', width: 140, headerAlign: 'right', align: 'right' },
	{ field: 'otros', headerName: 'Otros', width: 300, headerAlign: 'right', align: 'right' },
	{ field: 'referencia', headerName: 'Referencia', width: 500, headerAlign: 'right', align: 'right' },
	{ field: 'comuna', headerName: 'Comuna', width: 150, headerAlign: 'right', align: 'right' },
	{ field: 'emailc2', headerName: 'Email', width: 350, headerAlign: 'right', align: 'right' },
	{ field: 'id_zona', headerName: 'Zona', width: 120, headerAlign: 'right', align: 'right' },
]

export const getClienteDistancias = async (latitud, longitud) => {
	//const url = `${ apiUrl }/login`
	const url = `${apiEstanquesUrl}/cliente/clienteDistancias`

	const body = {
		latitud: latitud,
		longitud: longitud
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const { data, respuesta } = await resp.json()

			return {
				data: data,
				status: 'OK'
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las distancias`
		}
	}
}

export const getBloquesHorarios = async (id_zona, fecha) => {
	const url = `${apiEstanquesUrl}/agenda/bloquesHorarios`

	const body = {
		id_zona: id_zona,
		fecha: fecha
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				bloques: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los bloques horarios`
		}
	}
}

export const getHistoria = async (ot) => {
	const url = `${apiEstanquesUrl}/historia`

	const body = {
		ot: ot
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsHistoria,
				status: 'OK',
				message: ''
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener la historia`
		}
	}
}

export const columnsHistoria = [
	{ field: 'id', headerName: 'Historia', width: 130 },
	{ field: 'fecha_hora', headerName: 'Fecha - Hora', width: 220 },
	{ field: 'observacion', headerName: 'Observacion', width: 500 },
	{ field: 'accion', headerName: 'Acción', width: 200 },
	{ field: 'ot', headerName: 'OT', width: 180 }
]

export const getAgenda2 = async (fechaDesde, fechaHasta, estado, ot) => {
	const url = `${apiEstanquesUrl}/agenda/2`

	const body = {
		fechaDesde: fechaDesde,
		fechaHasta: fechaHasta,
		estado: estado,
		ot: ot
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsAgenda2,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsAgenda2 = [
	{ field: 'fecha', headerName: 'Fecha', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'hora_inicio', headerName: 'Hora Inicio', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'id_estado', headerName: 'ID Estado', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'descripcion', headerName: 'Descripcion', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'ot', headerName: 'OT', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'dm', headerName: 'DM', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'resultado_visita', headerName: 'Resultado Visita', width: 500, headerAlign: 'right', align: 'right' },
	{ field: 'codigo_tanque', headerName: 'Codigo Tanque', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'telefono', headerName: 'Teléfono', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'telefono2', headerName: 'Teléfono 2', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'razon_social', headerName: 'Razon Social', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'referencia', headerName: 'Referencia', width: 200, headerAlign: 'right', align: 'right' },

	{ field: 'calle', headerName: 'Calle', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'numero', headerName: 'Número', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'otros', headerName: 'Otros', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'emailc2', headerName: 'Email', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'referencia', headerName: 'Referencia', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'comuna', headerName: 'Comuna', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'cli_latitud', headerName: 'Latitud', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'cli_longitud', headerName: 'Longitud', width: 200, headerAlign: 'right', align: 'right' },

	{ field: 'id_externo', headerName: 'ID Externo', width: 200, headerAlign: 'right', align: 'right' },
]

export const getResultados = async (id_externo) => {
	const url = `${apiEstanquesUrl}/agenda/resultadoAgenda`

	const body = {
		id_externo: id_externo
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				resultadoAgenda: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los resultados`
		}
	}
}

export const generarURLFirmada = async (link) => {
	const url = `${apiEstanquesUrl}/arcgis/generarURLFirmada`;

	const regex = /^https:\/\/storage\.cloud\.google\.com\/([^/]+)\/(.+)$/;
	const match = link.match(regex);

	if (!match || match.length !== 3) {
		return {
			status: 'NOK',
			message: 'El enlace proporcionado no es válido'
		};
	}

	const bucketName = match[1];
	const fileName = match[2];

	const body = {
		bucketName: bucketName,
		fileName: fileName,
		expiration: 600
	};

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		});

		if (resp.status === 200) {
			const respuesta = await resp.json();

			return {
				status: 'OK',
				url: respuesta.mensaje_usr
			};
		} else {
			const { errors } = await resp.json();
			return {
				status: 'NOK',
				message: errors[0].msg
			};
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: 'No se pudo conectar para obtener los resultados'
		};
	}
}

export const postEstadoSAP = async (ot, estado, id_externo) => {
	const url = `${apiEstanquesUrl}/sap/estadoSAP`

	const body = {
		ot: ot,
		estado: estado,
		id_externo: id_externo
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const { respuesta } = await resp.json()

			return {
				message: respuesta.mensaje_usr,
				status: respuesta.mensaje_sis
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para enviar el estado a SAP`
		}
	}
}