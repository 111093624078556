import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getTanques } from '../../helpers/tanques/apiCalls'
import { TableBase } from './TableBase'
import { FormBase } from './FormBase'
import Grid from '@material-ui/core/Grid';
import Spinner from '../base/Spinner'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));

export const TanquesScreen = () => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [reload, setReload] = useState('');
  const [openSpinner, setOpenSpinner] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setOpenSpinner(true);
      getTanques()
        .then((result) => {
          setData(result);
          setOpenSpinner(false);
        });
    };
    fetchData();
    return () => { isMounted = false; };
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontFamily: '"Poppins", sans-serif' }}>
            Todos los Tanques en Estado AC
          </Typography>
          <Typography variant="p" style={{ fontFamily: '"Poppins", sans-serif' }}>
              Esta grilla solo muestra 45.000 registros
            </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormBase
            setReload={setReload}
            reload={reload}
          />
        </Grid>

        {data && data.columns &&
          <Grid item xs={12}>
            <TableBase
              getData={data}
              data={data.rows}
            />
          </Grid>
        }
      </Grid>

      <Spinner open={openSpinner} />

    </div>
  );
};
