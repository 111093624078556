import React, { useState, useEffect } from 'react'

import DataGridNoRows from '../../common/DataGridNoRows'
import { DataGrid } from '@material-ui/data-grid'
import { isEmpty } from 'lodash'
import { Modal, Box, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { InputText } from '../base/controls/InputText'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import moment from 'moment'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import Spinner from '../base/Spinner'

import { DialogAlert } from '../base/DialogAlert';
import { Dialog, DialogContent } from '@material-ui/core';

import { getClienteDistancias, getAcciones, getBloquesHorarios, addAgenda, getHistoria } from '../../helpers/estanques/apiCalls'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#F1F1F1'
    },
    bloques_div: {
        maxHeight: 200,
        width: 400,
        overflowY: 'auto',
    },
    modalScroll: {
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#F0F5FF',
        },
        '&::-webkit-scrollbar-thumb:vertical': {
            backgroundImage: 'linear-gradient(180deg, #FCDB00 -0.03%, #95C11F 24.46%, #36B37E 46.33%, #4589FF 63.52%, #1A53BA 80.19%, #24419E 99.98%)',
            borderRadius: '30px',
        },
        '&::-webkit-scrollbar-thumb:horizontal': {
            backgroundImage: 'linear-gradient(90deg, #FCDB00 -0.03%, #95C11F 24.46%, #36B37E 46.33%, #4589FF 63.52%, #1A53BA 80.19%, #24419E 99.98%)',
            borderRadius: '30px',
        },
    },
    listItemRed: {
        color: 'red',
    },
    listItemBlack: {
        color: 'back',
    },
});

export const TableBase = ({ getData, data = [] }) => {
    const classes = useStyles();

    const { columns } = getData
    const [modalAcciones, setModalAcciones] = useState([])
    const [distancias, setDistancias] = useState([]);
    const [acciones, setAcciones] = useState([])
    const [bloquesHorarios, setBloquesHorarios] = useState([])
    const [accion, setAccion] = useState(null)
    const [estado, setEstado] = useState(null)
    const [observacion, setObservacion] = useState('')
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedHora, setSelectedHora] = useState(null);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)
    const [historiaData, setHistoriaData] = useState({ rows: [], columns: [] })

    const [values, setValues] = useState({
        accion: 0
    })

    useEffect(() => {
        sessionStorage.removeItem('fechaReagendar')
        sessionStorage.setItem('fechaReagendar', JSON.stringify(moment(new Date()).format("YYYY-MM-DD")))
        const zona = isEmpty(JSON.parse(sessionStorage.getItem('zonaSeguimiento'))) ? modalAcciones.id_zona : JSON.parse(sessionStorage.getItem('zonaSeguimiento')).id_zona
        cargarBloquesHorarios(zona, sessionStorage.getItem('fechaReagendar'))
    }, []);

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    const buscarDistancia = (cliente, distancias) => {
        const distanciaEncontrada = distancias.find((d) => d.cliente === cliente);
        return distanciaEncontrada ? distanciaEncontrada.distancia : "";
    };

    const cargarBloquesHorarios = (idZona, fecha) => {
        setOpenSpinner(true);

        getBloquesHorarios(idZona, fecha)
            .then(({ bloques }) => {
                setOpenSpinner(false);
                setBloquesHorarios(bloques);
            })
            .catch(error => {
                setOpenSpinner(false);
                console.error('Error al cargar bloques horarios', error);
            });
    };

    const handleChangeForm = (name, value) => {
        if (name === "fechaReagendar") {
            sessionStorage.setItem('fechaReagendar', JSON.stringify(moment(value).format("YYYY-MM-DD")))

            setBloquesHorarios([]);
            const zona = isEmpty(JSON.parse(sessionStorage.getItem('zonaSeguimiento'))) ? modalAcciones.id_zona : JSON.parse(sessionStorage.getItem('zonaSeguimiento')).id_zona
            getBloquesHorarios(zona, sessionStorage.getItem('fechaReagendar'))
                .then(({ bloques }) => {
                    setOpenSpinner(false);
                    setBloquesHorarios(bloques);
                });
        } else if (name === 'accion') {
            setAccion(value.id_accion);
            setEstado(value.cambio_estado)
            if (value.id_accion != 3) {
                setSelectedValue(null)
                setSelectedHora(null)
            }
        } else if (name === 'observacion') {
            setObservacion(value);
        }
    }

    useEffect(() => {
        const zona = isEmpty(JSON.parse(sessionStorage.getItem('zonaSeguimiento'))) ? modalAcciones.id_zona : JSON.parse(sessionStorage.getItem('zonaSeguimiento')).id_zona
        cargarBloquesHorarios(zona, sessionStorage.getItem('fechaReagendar'));
    }, [modalAcciones.id_zona, values.fecha]);

    const openModalAcciones = () => {
        handleOpen();
        setAccion(null);
        setObservacion('');
        setSelectedValue(null);

        setOpenSpinner(true);

        getAcciones(2)
            .then(({ acciones }) => {
                setOpenSpinner(false);
                setAcciones(acciones);
            })
            .catch(error => {
                setOpenSpinner(false);
                console.error('Error al cargar acciones', error);
            });
    };

    const columnAcciones = {
        field: "X", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', description: 'Cliente',
        renderCell: (modal) => (
            <button onDoubleClick={openModalAcciones} title="Abrir Cliente ¡Doble Click!">☰</button>
        )
    }

    const allColumns = [columnAcciones].concat(columns)
    //allColumns.reverse()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        width: '60%',
        height: '80%',
        overflow: 'scroll',
    };

    const localizedTextsMap = {
        columnMenuUnsort: "Sin orden",
        columnMenuSortAsc: "Orden ascendente",
        columnMenuSortDesc: "Orden desendente",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas"
    }

    const handleCheckboxChange = (value) => {
        setSelectedValue(value.id_bloque_horario);

        const parsedDateTime = moment(value.hora, 'HH:mm:ss Z');
        setSelectedHora(parsedDateTime.format('HH:mm:ss ZZ'));
    };

    const guardar = (e) => {
        e.preventDefault();

        //console.log(accion)

        if (accion === null) {
            setMessage("Debe seleccionar una acción para guardar...");
            setCodeAlert(false);
            setOpenAlert(true);
            return;
        } else if (accion == 3 && selectedValue == null) {
            setMessage("Debe seleccionar un bloque horario para guardar...");
            setCodeAlert(false);
            setOpenAlert(true);
            return;
        }

        const val = bloquesHorarios.find(
            (item) => selectedValue == item.id_bloque_horario && item.id_estado != 1
        );

        if (!val || window.confirm(`Este bloque se encuentra ${val.descripcion}. ¿Está seguro que desea reagendar de todas maneras?`)) {
            guardarRegistro();
        }
    };

    const guardarRegistro = () => {
        setOpenSpinner(true);

        addAgenda({
            id_zona: modalAcciones.id_zona,
            fecha: sessionStorage.getItem('fechaReagendar'), //values.fecha,
            hora_inicio: selectedHora,
            id_bloque_horario: selectedValue,
            descripcion: observacion,
            id_cliente: modalAcciones.cliente,
            id_estado: estado,
            id_estanque: modalAcciones.codigo_tanque,
            ot: modalAcciones.ot,
            id_accion: accion,
            usuario: localStorage.getItem('usuario'),
        }).then(({ message, status }) => {
            setMessage(message === "" ? "Error al guardar" : message);
            setCodeAlert(status === "OK");
            setOpenAlert(true);
            setOpenSpinner(false);

            if (status === "OK") {
                handleClose();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        });
    };

    const handleVerHistoriaClick = async (ot) => {
        try {
            setOpenSpinner(true);

            const { rows, columns, status, message } = await getHistoria(ot);

            if (status === 'OK' && rows != null) {
                setHistoriaData({ rows, columns });
                setOpenDialog(true);
            } else {
                setOpenSpinner(false);
                setMessage(message === "" ? "No existe historia para esta OT" : message)
                setCodeAlert(status === "NOK")
                setOpenAlert(true)
                setOpenSpinner(false)
            }
        } catch (error) {
            setOpenSpinner(false);
            console.error('Error obteniendo la historia:', error.message);
        }
    };

    const closeModalHistoria = () => {
        setOpenDialog(false)
        setOpenSpinner(false)
    }

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={allColumns}
                hideFooter={false}
                components={{
                    NoRowsOverlay: () => DataGridNoRows("no-data"),
                }}
                onCellDoubleClick={(params, event) => {
                    const selectedRowData = params.row
                    if (selectedRowData) {
                        const distClient = buscarDistancia(selectedRowData.cliente, distancias)
                        setModalAcciones({
                            ...selectedRowData,
                            distancia: distClient,
                        });
                        console.log(selectedRowData)
                    }
                }}
                localeText={localizedTextsMap}
            />

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className={classes.modalScroll}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            DM: {modalAcciones.dm} - OT: {modalAcciones.ot} - Cod. Tanque: {modalAcciones.codigo_tanque}
                        </Typography>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <br></br>
                            <Spinner open={openSpinner} />
                        </Typography>
                        <br></br>
                        <Grid container direction="row" justifyContent="space-between" >
                            <InputText
                                name="nombre"
                                label="Nombre"
                                disabled={true}
                                type="text"
                                defaultValue={modalAcciones.razon_social}
                                widthStyle='long'
                                variant="outlined"
                            />
                            <ButtonControl
                                label="Ver Historia"
                                onClick={() => handleVerHistoriaClick(modalAcciones.ot)}
                            />
                        </Grid>
                        <br></br>
                        <Grid container direction="row">
                            <InputText
                                name="calle"
                                label="Calle"
                                disabled={true}
                                type="text"
                                defaultValue={modalAcciones.calle}
                                widthStyle='long'
                                variant="outlined"
                            />
                            <InputText
                                name="numero"
                                label="Número"
                                disabled={true}
                                type="text"
                                defaultValue={modalAcciones.numero}
                                widthStyle='medium'
                                variant="outlined"
                            />
                        </Grid>
                        <br></br>
                        <Grid container direction="row">
                            <InputText
                                name="comuna"
                                label="Comuna"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.c_comuna} - ${modalAcciones.comuna}`}
                                widthStyle='medium'
                                variant="outlined"
                            />
                        </Grid>
                        <br></br>
                        <Grid container direction="row">
                            <InputText
                                name="telefono"
                                label="Teléfono 1"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.telefono}`}
                                widthStyle='medium'
                                variant="outlined"
                            />
                            <InputText
                                name="telefono"
                                label="Teléfono 2"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.telefono2}`}
                                widthStyle='medium'
                                variant="outlined"
                            />
                            <InputText
                                name="correo"
                                label="Correo"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.emailc2}`}
                                widthStyle='long'
                                variant="outlined"
                            />
                        </Grid>
                        <br></br><br></br>
                        <Divider />
                        <br></br><br></br>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            Acciones
                        </Typography>
                        <br></br><br></br>
                        <Grid container direction="row" justifyContent="space-between">
                            <Autocomplete
                                id="size-small-outlined"
                                size="small"
                                disabled={modalAcciones.id_estado != "2" && modalAcciones.id_estado != "3"}
                                className={classes.formControl}
                                options={acciones}
                                getOptionLabel={(option) => `${option.descripcion}`}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                    if (!isEmpty(newValue)) {
                                        handleChangeForm("accion", newValue)
                                    } else {
                                        handleChangeForm("accion", null)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Acción" placeholder="Seleccione Acción" style={{ marginLeft: 8, width: 400 }} />
                                )}
                            />
                            <Grid item>
                                <TextField
                                    disabled={accion != 3}
                                    id="fechaReagendar"
                                    label="Fecha"
                                    type="date"
                                    defaultValue={moment(sessionStorage.getItem('fechaReagendar')).format("YYYY-MM-DD")}
                                    className={classes.textField}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        handleChangeForm("fechaReagendar", newValue);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container direction="row" justifyContent="space-between">
                            <TextField
                                id="observacion"
                                label="Observación"
                                multiline
                                rows={4}
                                defaultValue=""
                                variant="outlined"
                                style={{ marginLeft: 8, width: 400 }}
                                onChange={(event) => {
                                    const newValue = event.target.value;

                                    if (!isEmpty(newValue)) {
                                        handleChangeForm("observacion", newValue);
                                    } else {
                                        handleChangeForm("observacion", null);
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <div className={classes.bloques_div}>
                                <List className={classes.root}>
                                    {bloquesHorarios && bloquesHorarios.map((value) => {
                                        const labelId = `checkbox-list-label-${value.id_bloque_horario}`;

                                        return (
                                            <ListItem
                                                key={value.id_bloque_horario}
                                                role={undefined}
                                                dense
                                                button
                                                onClick={() => handleCheckboxChange(value)}
                                                disabled={accion != 3}
                                                className={value.id_estado != 1 ? classes.listItemRed : classes.listItemBlack}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={selectedValue === value.id_bloque_horario}
                                                        onChange={() => handleCheckboxChange(value)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        color='#000000'
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${value.hora}`} />
                                                <ListItemText id={labelId} primary={`${value.descripcion}`} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </div>
                        </Grid>
                        <br></br>
                        <Typography id="modal-modal-title" variant="h6" component="h6" align='right'>
                            Distancia: {modalAcciones.distancia}
                        </Typography>
                        <br></br><br></br>
                        <Divider />
                        <br></br><br></br>
                        <Grid container direction="row" justifyContent="space-evenly" alignItems="center" >
                            <ButtonControl
                                label="Agregar"
                                onClick={guardar}
                            />
                        </Grid>
                    </Box>
                </Modal>
                <Dialog open={openDialog} onClose={() => closeModalHistoria()} maxWidth="lg" fullWidth>
                    <DialogContent>
                        <br></br>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            Historia de OT {modalAcciones.ot}
                        </Typography>
                        <br></br><br></br>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={historiaData.rows}
                                columns={historiaData.columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                pagination
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />

            <div style={{
                paddingTop: 30,
                backgroundColor:
                    'white',
                display: 'flex',
                flexDirection: 'row-reverse'
            }}
            >
            </div>

        </div>
    )
}

