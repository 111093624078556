import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Spinner from '../base/Spinner';
import { getResultados, postEstadoSAP, generarURLFirmada } from '../../helpers/estanques/apiCalls';
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Box, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import { DialogAlert } from '../base/DialogAlert'

export const TableBase = ({ getData, data = [] }) => {
    const { columns } = getData;

    const [resultados, setResultados] = useState([])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [idExterno, setIdExterno] = useState('')

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: '#F1F1F1'
        },
        bloques_div: {
            maxHeight: 200,
            width: 300,
            overflowY: 'auto',
        }
    });

    const classes = useStyles();

    const columnResultados = {
        field: "X", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', description: 'Resultados',
        renderCell: (modal) => (
            <button onDoubleClick={handleVerResultadosClick} title="Abrir Resultados ¡Doble Click!">☰</button>
        )
    }

    const allColumns = [columnResultados, ...columns];

    const localizedTextsMap = {
        columnMenuUnsort: "Sin orden",
        columnMenuSortAsc: "Orden ascendente",
        columnMenuSortDesc: "Orden descendente",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas",
    };

    const handleVerResultadosClick = async () => {
        try {
            handleOpen();
            setOpenSpinner(true);

            getResultados(idExterno)
                .then(({ resultadoAgenda }) => {
                    setOpenSpinner(false);
                    setResultados(resultadoAgenda);
                })
                .catch(error => {
                    setOpenSpinner(false);
                    console.error('Error al cargar los resultados', error);
                });
        } catch (error) {
            setOpenSpinner(false);
            console.error('Error obteniendo los resultados:', error.message);
        }
    };

    const enviarASAP = (ot, id_externo) => {
        const estado = 'ENEJ' //'EJEC'
        postEstadoSAP(ot, estado, id_externo)
            .then(({ message, status }) => {
                setMessage(message === "" ? "Error al enviar" : message);
                setCodeAlert(status === "OK");
                setOpenAlert(true);
                setOpenSpinner(false);
                if (status === "OK") {
                    handleClose();
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            });
    }

    function handleClick(link) {
        generarURLFirmada(link)
            .then(({ url }) => {
                window.open(url, '_blank');
            });
    }

    return (
        <div style={{ height: 500, width: '100%' }}>
            <span>Esta grilla solo permite visualizar los primeros 100 registros coincidentes con los filtros</span>
            <DataGrid
                rows={data}
                columns={allColumns}
                hideFooter={false}
                localeText={localizedTextsMap}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = data.filter((row) => selectedIDs.has(row.id.toString()));

                    if (selectedRowData && selectedRowData.length > 0) {
                        setIdExterno(selectedRowData[0].id_externo)
                    }
                }}
            />
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        height: 800,
                        overflowY: 'auto',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            <b>Resultado de la visita</b>
                        </Typography>
                        <br></br>
                        <br></br>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {!resultados ? <span> No existen resultados para esta visita</span> : resultados.map((item, index) => (
                                <li key={index}>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li><span style={{ paddingRight: 10 }}><b>objectid</b>:</span> <span>{item.objectid}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>dm</b>:</span> <span>{item.dm}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>motivo</b>:</span> <span>{item.motivo}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>tipo_tanque</b>:</span> <span>{item.tipo_tanque}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>capacidad_tanque</b>:</span> <span>{item.capacidad_tanque}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>codigo_barra</b>:</span> <span>{item.codigo_barra}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>serie</b>:</span> <span>{item.serie}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>fecha_f</b>:</span> <span>{item.fecha_f}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>fecha_u</b>:</span> <span>{item.fecha_u}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>empresa_inspectora</b>:</span> <span>{item.empresa_inspectora}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>empresa_certi</b>:</span> <span>{item.empresa_certi}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>numero_certifi</b>:</span> <span>{item.numero_certifi}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>porcentaje</b>:</span> <span>{item.porcentaje}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>codigo_estanque</b>:</span> <span>{item.codigo_estanque}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>tipo_reg</b>:</span> <span>{item.tipo_reg}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>marca_reg</b>:</span> <span>{item.marca_reg}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>modelo_reg</b>:</span> <span>{item.modelo_reg}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>tipo_camion</b>:</span> <span>{item.tipo_camion}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>distancia_camion</b>:</span> <span>{item.distancia_camion}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>llave</b>:</span> <span>{item.llave}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>obs1</b>:</span> <span>{item.obs1}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>obs2</b>:</span> <span>{item.obs2}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>referencia</b>:</span> <span>{item.referencia}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>nombre_tecnico</b>:</span> <span>{item.nombre_tecnico}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>empresa_contra</b>:</span> <span>{item.empresa_contra}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>nombre_cliente</b>:</span> <span>{item.nombre_cliente}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>cargo_cliente</b>:</span> <span>{item.cargo_cliente}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>rut_cliente</b>:</span> <span>{item.rut_cliente}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>telefono</b>:</span> <span>{item.telefono}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>mail</b>:</span> <span>{item.mail}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>check_lock</b>:</span> <span>{item.check_lock}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>problemas_acceso</b>:</span> <span>{item.problemas_acceso}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>complicacion_camion</b>:</span> <span>{item.complicacion_camion}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>rep_pasto</b>:</span> <span>{item.rep_pasto}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>rep_bases</b>:</span> <span>{item.rep_bases}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>resultado_visita</b>:</span> <span>{item.resultado_visita}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>globalid</b>:</span> <span>{item.globalid}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>created_user</b>:</span> <span>{item.created_user}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>created_date</b>:</span> <span>{item.created_date}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>last_edited_user</b>:</span> <span>{item.last_edited_user}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>last_edited_date</b>:</span> <span>{item.last_edited_date}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>listo_para_cambio</b>:</span> <span>{item.listo_para_cambio}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>capacidad_otra</b>:</span> <span>{item.capacidad_otra}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>ot</b>:</span> <span>{item.ot}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>id_externo</b>:</span> <span>{item.id_externo}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>Estado Agenda</b>:</span> <span>{item.id_estado}</span></li>

                                        {item.links_privados && item.links_privados.length > 0 && (
                                            <li>
                                                <b>Fotos:</b>
                                                <ul>
                                                    {item.links_privados.map((link, linkIndex) => (
                                                        <li key={linkIndex}>
                                                            <a href="#" onClick={() => handleClick(link)}>Imagen {linkIndex + 1}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                    </ul>
                                    <div style={{ marginTop: 20 }}>
                                        <Button onClick={handleClose} variant="contained" color="primary" style={{ marginRight: 8 }}>Cerrar</Button>
                                        <Button onClick={() => enviarASAP(item.ot, item.id_externo)} variant="contained" color="primary" disabled={item.id_estado !== '8' ? true : false}>Enviar a SAP</Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Box>
                </Modal>
            </div>

            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }

            <Spinner open={openSpinner} />

            <div style={{
                paddingTop: 30,
                backgroundColor:
                    'white',
                display: 'flex',
                flexDirection: 'row-reverse'
            }}
            >
            </div>
        </div>
    );
};
