import React, { useState, createContext } from 'react'

const menuOptionDefault = [
    {
        page: "agendar",
        default: "agendar",
        name: "agendar",
        path: "agendar",
        permiso: 920,
        text: 'agendar',
    },
    {
        page: "seguimiento",
        default: "seguimiento",
        name: "seguimiento",
        path: "seguimiento",
        permiso: 921,
        text: 'Sectorizacion',
    },
    {
        page: "gestion",
        default: "gestion",
        name: "gestion",
        path: "gestion",
        permiso: 922,
        text: 'gestion',
    },
    {
        page: "agendar-cambio",
        default: "agendar-cambio",
        name: "agendar-cambio",
        path: "agendar-cambio",
        permiso: 923,
        text: 'agendar-cambio',
    },
    {
        page: "seguimiento-cambio",
        default: "seguimiento-cambio",
        name: "seguimiento-cambio",
        path: "seguimiento-cambio",
        permiso: 924,
        text: 'Sectorizacion-cambio',
    },
    {
        page: "gestion-cambio",
        default: "gestion-cambio",
        name: "gestion-cambio",
        path: "gestion-cambio",
        permiso: 925,
        text: 'gestion-cambio',
    },
    {
        page: "tanques",
        default: "tanques",
        name: "tanques",
        path: "tanques",
        permiso: 926,
        text: 'tanques',
    },
    /*
    {
        page: "despachos",
        default: "despachos",
        name: "despachos",
        path: "despachos",
        permiso: 923,
        text: 'despachos',
    },
    */
]

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [userContextState, setUserContextState] = useState({
        menu: menuOptionDefault,
        claims: []
    })

    return(
        <UserContext.Provider value={{
            userContextState, setUserContextState
        }}>
            { children }
        </UserContext.Provider>
   )
  
}
