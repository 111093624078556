import { isEmpty } from 'lodash'
const apiEstanquesUrl = process.env.REACT_APP_API_ESTANQUES_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')


const token = localStorage.getItem('token');

export const getDespachosByFilters = async (comuna, mesDesde, mesHasta) => {
	const url = `${apiEstanquesUrl}/cambio`

	const raw = {
		"comuna": isEmpty(comuna) ? null : comuna,
		"mesDesde": isEmpty(mesDesde) ? null : mesDesde,
		"mesHasta": isEmpty(mesHasta) ? null : mesHasta,
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsDespachos,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los despachos`
		}
	}
}

const columnsDespachos = [
	{ field: 'comuna', headerName: 'Comuna', width: 200 },
	{ field: 'formato', headerName: 'Formato', width: 200 },
	{ field: 'cantidadtks', headerName: 'Cant. Tanques', width: 200 },
	{ field: 'fecha_solicitud', headerName: 'Fecha de solicitud', width: 250 },
	{ field: 'pins', headerName: 'PINS', width: 200 },
	{ field: 'contacto', headerName: 'Contacto', width: 200 },
	{ field: 'georreferencia', headerName: 'Georreferencia', width: 200 },
	{ field: 'info_adic', headerName: 'Info. Adicional', width: 300, editable: true },
]

/*
LINK DE VISITA	
DM	
FECHA DE REVISIÓN (FECHA MODIFICABLE DIRECTAMENTE EN PANEL)// EJ: DIA ESPECIFICO DE CONTACTO CON CLIENTE)	
MOTIVO DE GESTIÓN (SE AGREGA EN EL PANEL, SE MODIFICA DIRECTAMENTE EN EL PANEL)	
FECHA DE QUIEBRE	
FECHA DE VENCIMIENTO	
NOMBRE DE CLIENTE	
CONTACTO	
COMUNA	
RESULTADO DE VISITA	
% DE GAS	
CHECK LOCK	
TIPO DE CAMIÓN	
RITMO DE CONSUMO	
OBSERVACIONES DE VISITA	
OC / EM	
TELEMETRIA	
N° DE TICKET ASOCIADO	

*/

export const addAgenda = async (values) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiEstanquesUrl}/agenda/addAgenda`

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(values),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const respuesta = await resp.json()

			if (respuesta.cod_error === 9000) {
				return {
					message: 'Guardado exitosamente',
					status: 'OK'
				}
			} else {
				return {
					message: 'No se pudo guardar',
					status: 'NOK'
				}
			}

		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para guardar`
		}
	}
}

export const getAcciones = async (tipo) => {
	const url = `${apiEstanquesUrl}/accion`

	const raw = {
		"tipo": tipo
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				acciones: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las acciones`
		}
	}
}

export const getBloquesHorarios = async (id_zona, fecha) => {
	const url = `${apiEstanquesUrl}/agenda/bloquesHorarios`

	const body = {
		id_zona: id_zona,
		fecha: fecha
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				bloques: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los bloques horarios`
		}
	}
}

export const getClienteDistancias = async (latitud, longitud) => {
	//const url = `${ apiUrl }/login`
	const url = `${apiEstanquesUrl}/cliente/clienteDistancias`

	const body = {
		latitud: latitud,
		longitud: longitud
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const { data, respuesta } = await resp.json()

			return {
				data: data,
				status: 'OK'
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las distancias`
		}
	}
}

export const getHistoria = async (ot) => {
	const url = `${apiEstanquesUrl}/historia`

	const body = {
		ot: ot
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsHistoria,
				status: 'OK',
				message: ''
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener la historia`
		}
	}
}

export const columnsHistoria = [
	{ field: 'id', headerName: 'Historia', width: 130 },
	{ field: 'fecha_hora', headerName: 'Fecha - Hora', width: 220 },
	{ field: 'observacion', headerName: 'Observacion', width: 500 },
	{ field: 'accion', headerName: 'Acción', width: 200 },
	{ field: 'ot', headerName: 'OT', width: 180 }
]

export const getCanales = async (values) => {
	const url = `${apiEstanquesUrl}/cliente/canales`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				canales: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los canales`
		}
	}
}

export const getMonthYear = async (values) => {
	const url = `${apiEstanquesUrl}/cliente/monthYear`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				monthYear: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los meses y años`
		}
	}
}

export const getOrgVentas = async (values) => {
	const url = `${apiEstanquesUrl}/cliente/orgVentas`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				orgVentas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las OrgVentas`
		}
	}
}

export const getZonas = async (values) => {
	const url = `${apiEstanquesUrl}/zona`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				zonas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las zonas`
		}
	}
}

export const getResultados = async (id_externo) => {
	const url = `${apiEstanquesUrl}/agenda/resultadoAgenda`

	const body = {
		id_externo: id_externo
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				resultadoAgenda: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los resultados`
		}
	}
}

export const generarURLFirmada = async (link) => {
	const url = `${apiEstanquesUrl}/arcgis/generarURLFirmada`;

	const regex = /^https:\/\/storage\.cloud\.google\.com\/([^/]+)\/(.+)$/;
	const match = link.match(regex);

	if (!match || match.length !== 3) {
		return {
			status: 'NOK',
			message: 'El enlace proporcionado no es válido'
		};
	}

	const bucketName = match[1];
	const fileName = match[2];

	const body = {
		bucketName: bucketName,
		fileName: fileName,
		expiration: 600
	};

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		});

		if (resp.status === 200) {
			const respuesta = await resp.json();

			return {
				status: 'OK',
				url: respuesta.mensaje_usr
			};
		} else {
			const { errors } = await resp.json();
			return {
				status: 'NOK',
				message: errors[0].msg
			};
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: 'No se pudo conectar para obtener los resultados'
		};
	}
}