import React, { useState, useEffect } from 'react' //, useContext 
import { makeStyles } from '@material-ui/core/styles'
import { getEstanquesByFilters } from '../../helpers/estanques/apiCalls'
import { TableBase } from './TableBase'
import { FormBase } from './FormBase'
//import { ActionBase } from './ActionBase'
import Grid from '@material-ui/core/Grid';
//import { GeneralContext } from '../../context/GeneralContext'
import { isEmpty } from 'lodash'
import Spinner from '../base/Spinner'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));

export const AgendarScreen = () => {
  //const { stateGeneral } = useContext(GeneralContext)
  const classes = useStyles();
  const [data, setData] = useState({})
  const [reload, setReload] = useState('')
  const [openSpinner, setOpenSpinner] = useState(false)


  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      setOpenSpinner(true)
      //getEstanquesByFilters(isEmpty(stateGeneral.filtersExportSll_agendar) ? {} : stateGeneral.filtersExportSll_agendar)
      const zona = isEmpty(JSON.parse(sessionStorage.getItem('zonaAgendar'))) ? null : JSON.parse(sessionStorage.getItem('zonaAgendar')).id_zona
      const canal = isEmpty(JSON.parse(sessionStorage.getItem('canal'))) ? null : JSON.parse(sessionStorage.getItem('canal')).can_codigo
      const orgVentas = isEmpty(JSON.parse(sessionStorage.getItem('orgVentas'))) ? null : JSON.parse(sessionStorage.getItem('orgVentas')).orgvta
      const mesDesde = isEmpty(JSON.parse(sessionStorage.getItem('mesDesde'))) ? null : JSON.parse(sessionStorage.getItem('mesDesde'))
      const mesHasta = isEmpty(JSON.parse(sessionStorage.getItem('mesHasta'))) ? null : JSON.parse(sessionStorage.getItem('mesHasta'))
      const frecuencia = isEmpty(JSON.parse(sessionStorage.getItem('frecuencia'))) ? null : JSON.parse(sessionStorage.getItem('frecuencia'))
      const tipo = isEmpty(JSON.parse(sessionStorage.getItem('tipo'))) ? null : JSON.parse(sessionStorage.getItem('tipo')).valor
      getEstanquesByFilters(zona, canal, orgVentas, mesDesde, mesHasta, frecuencia, tipo)
        .then((result) => {
          setData(result)
          setOpenSpinner(false)
        })
    }
    fetchData()
    return () => { isMounted = false }
  }, [reload])

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontFamily: '"Poppins", sans-serif' }} >
            Agendar Inspección
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormBase
            setReload={setReload}
            reload={reload}
          />
        </Grid>
        {data && data.columns &&
          <Grid item xs={12}>
            <TableBase
              getData={data}
              data={data.rows}
            />
          </Grid>
        }
      </Grid>

      <Spinner open={openSpinner} />

    </div>
  )
}
