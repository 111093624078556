import React, { useContext, useState } from 'react'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { startCase } from 'lodash'
import { UserContext } from '../../context/UserContext'
import {
  PeopleAlt as UserIcon,
  DateRange as AgendarIcon,
  EventAvailable as SeguimientoIcon,
  EventNote as GestionIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
  Restore as AgendarCambioIcon,
  YoutubeSearchedFor as SeguimientoCambioIcon,
  SettingsBackupRestore as GestionCambioIcon,
  BatteryUnknown as TanquesIcon,
  LocalShipping as  DespachosIcon
} from '@material-ui/icons'
import MenuItem from '@material-ui/core/MenuItem'
import { logout } from '../../helpers/util'
import { isEmpty } from 'lodash'

const drawerWidth = 240;
const getIcon = (page) => {
  switch (page) {
    case "agendar":
      return <AgendarIcon />
    case "seguimiento":
      return <SeguimientoIcon />
    case "gestion":
      return <GestionIcon />
    case "agendar-cambio":
      return <AgendarCambioIcon />
    case "seguimiento-cambio":
      return <SeguimientoCambioIcon />
    case "gestion-cambio":
      return <GestionCambioIcon />
    case "tanques":
      return <TanquesIcon />
    case "despachos":
      return <DespachosIcon />
    default:
      return <UserIcon />
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FCDB00',
    color: '#2C3E5D',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifycontent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuSelectedColor: {
    root: {
      '&$selected': {
        backgroundColor: 'red',
        '&:hover': {
          backgroundColor: 'yellow',
        }
      },
    },
    selected: {},
  }
}));

export const DrawerBase = ({ content, permisoRuta, permisosUser }) => {
  const { userContextState, setUserContextState } = useContext(UserContext)
  const { claims } = userContextState
  //const perfil = localStorage.getItem("perfil") //!isEmpty(claims[1]) ? claims[1].valor : ""
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  //  const [selectedMenu, setSelectedMenu] = useState([])

  const handleClose = (e) => {
    setUserContextState({ ...userContextState, claims: [] })
    logout()
    window.location.href = '/login'
  }

  const items = userContextState.menu.map(menu => {

    /*
        if (window.location.pathname == '/toma-pedidos') {
          localStorage.setItem('selectedMenu', "Toma Pedidos")
        }
    */

    if (permisosUser.includes(menu.permiso)) {
      return {
        text: startCase(menu.page),
        src: menu.path,
        vista: menu.page,
        icon: getIcon(menu.page),
        selected: startCase(menu.page) === localStorage.getItem('selectedMenu') ? true : false
      }
    }
  })

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ fontFamily: '"Poppins", sans-serif' }} noWrap>
            Mantención de Tanques
          </Typography>
          <MenuItem
            onClick={handleClose} style={{ position: 'absolute', bottom: 0, right: 0, paddingBottom: 20, fontFamily: '"Poppins", sans-serif' }}>
            Cerrar Sesión
          </MenuItem>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <img src="/Lipigas_color_horizontal.svg" alt="logo" style={{ width: '8%', marginLeft: '-1300px', marginTop: '0px', paddingTop: '0px' }} />
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {
            items.map((item, index) => {
              return item  //=== "administrador" //perfil === item.vista || permisosUser.includes(permisoRuta)
                ?
                <Tooltip title={`${item.text}`} placement="right">
                  <ListItem
                    button
                    key={index}
                    component={Link}
                    to={`${item.src}`}
                    selected={item.selected}
                    classes={{ root: classes.menuSelectedColor.root, selected: classes.menuSelectedColor.selected }}
                    onClick={() => localStorage.setItem('selectedMenu', item.text) //setSelectedMenu(item.text)
                    }

                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText><p className={classes.textMenu}>{item.text}</p></ListItemText>

                  </ListItem>
                </Tooltip>
                : ""
            }
            )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {
          content
        }
      </main>
    </div>
  );
}