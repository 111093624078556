import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import Spinner from '../base/Spinner';

export const TableBase = ({ getData, data = [] }) => {
    const { columns } = getData;
    const [openSpinner, setOpenSpinner] = useState(false)

    const localizedTextsMap = {
        columnMenuUnsort: "Sin orden",
        columnMenuSortAsc: "Orden ascendente",
        columnMenuSortDesc: "Orden descendente",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas",
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid
                disableSelectionOnClick
                rows={data}
                columns={columns}
                hideFooter={false}
                localeText={localizedTextsMap}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />

            <Spinner open={openSpinner} />

            <div style={{
                paddingTop: 30,
                backgroundColor:
                    'white',
                display: 'flex',
                flexDirection: 'row-reverse'
            }}
            >
            </div>
        </div>
    );
};
