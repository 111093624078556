import React, { useState, useEffect, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getZonas } from '../../helpers/estanques/apiCalls'
import { GeneralContext } from '../../context/GeneralContext'
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [zonas, setZonas] = useState([])

    const [fechaDesde, setFechaDesde] = useState()
    const [fechaHasta, setFechaHasta] = useState()
    const [OT, setOT] = useState('')

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }


    useEffect(() => {
        let isMounted = true
        setOpenSpinner(true)

        if (!sessionStorage.getItem('fechaDesde')) {
            sessionStorage.setItem('fechaDesde', moment(new Date()).format("YYYY-MM-DD"))
        }

        if (!sessionStorage.getItem('fechaHasta')) {
            sessionStorage.setItem('fechaHasta', moment(new Date()).format("YYYY-MM-DD"))
        }

        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [])


    const onHandleSubmit = e => {
        e.preventDefault()
        setReload(new Date())
    }

    const handleDateChangeDesde = (date) => {
        sessionStorage.setItem('fechaDesde', moment(date.target.value).format("YYYY-MM-DD"))
        setFechaDesde(date.target.value);
        console.log(date.target.value)
    };

    const handleDateChangeHasta = (date) => {
        sessionStorage.setItem('fechaHasta', moment(date.target.value).format("YYYY-MM-DD"))
        setFechaHasta(date.target.value);
    };

    const handleOTChange = (ot) => {
        sessionStorage.setItem('ot', ot.target.value)
        setOT(ot.target.value);
    };

    const estados = [
        { id: 1, estado: 'Disponible' },
        { id: 2, estado: 'Agendado' },
        { id: 3, estado: 'Confirmado' },
        { id: 4, estado: 'Anulado' },
        { id: 5, estado: 'Archivo Generado' },
        { id: 6, estado: 'Cargado en ArcGig' },
        { id: 7, estado: 'Eliminado en ArcGis' },
        { id: 8, estado: 'Completado en ArcGis' },
        { id: 9, estado: 'Pendiente en ArcGis' },
        { id: 10, estado: 'Enviado a SAP' },
    ]

    return (
        <div className={classes.root}>
            <form onSubmit={onHandleSubmit}>
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={12}>
                        <Grid container justifycontent="center" spacing={8}>
                            <Grid item>
                                <TextField
                                    id="fechaDesde"
                                    label="Fecha Desde"
                                    type="date"
                                    value={sessionStorage.getItem('fechaDesde')}
                                    className={classes.textField}
                                    onChange={handleDateChangeDesde}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="fechaHasta"
                                    label="Fecha Hasta"
                                    type="date"
                                    value={sessionStorage.getItem('fechaHasta')}
                                    className={classes.textField}
                                    onChange={handleDateChangeHasta}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                {estados && estados.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={estados}
                                        getOptionLabel={(option) => `${option.id} - ${option.estado}`}
                                        defaultValue={(() => {
                                            const stored = sessionStorage.getItem('estado');
                                            if (stored) {
                                                const parsed = JSON.parse(stored);
                                                const found = zonas.find(zona => zona.id == parsed.id);
                                                if (found) {
                                                    const index = zonas.indexOf(found);
                                                    return zonas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('estado', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('estado')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Estado OT" placeholder="Seleccione Estado OT" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <TextField
                                id="ot"
                                className={classes.textField}
                                    name="ot"
                                    label="OT"
                                    type="number"
                                    value={sessionStorage.getItem('ot')}
                                    lengthMax={12}
                                    onChange={handleOTChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ marginTop: 8 }}>
                                <ButtonControl
                                    label="Buscar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />
        </div>
    )
}


