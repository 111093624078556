//export function handleApiError (callDataObject, setMessage, setOpenAlert, setOpenSpinner) {
export function handleApiError (callDataObject) {
    if(callDataObject.status !== "OK") {
        // setMessage(callDataObject.message)
        // setOpenAlert(true)
        // setOpenSpinner(false)
        alert('Error api...')
    }
}

const llaveToken = "token"
const llaveExpiracion = "token-expiracion"
const llaveUsuario = "usuario"
const llaveNombreUsuario = "nombreUsuario"
const llavePermisos = "permisos"

export function guardarTokenLocalStorage(autenticacion){
    localStorage.setItem(llaveToken, autenticacion.token);
    localStorage.setItem(llaveExpiracion, autenticacion.expiracion.toString());

    localStorage.setItem(llaveUsuario, autenticacion.usuario);
    localStorage.setItem(llaveNombreUsuario, autenticacion.nombre);
    localStorage.setItem(llavePermisos, autenticacion.permisos);
}

export function obtenerClaims() {
    const token = localStorage.getItem(llaveToken);

    const usuario = localStorage.getItem(llaveUsuario);
    const nombre = localStorage.getItem(llaveNombreUsuario);
    const permisos = localStorage.getItem(llavePermisos);

    if (!token || !usuario || !nombre || !permisos){
        return [];
    }
    
    const expiracion = localStorage.getItem(llaveExpiracion);
    const expiracionFecha = new Date(expiracion);

    if (expiracionFecha <= new Date()){
        logout();
        return [];
    }

/*
    const dataToken = JSON.parse(atob(token.split(".")[1])); 
    const respuesta = [];
    for (const propiedad in dataToken){
        respuesta.push({nombre: propiedad, valor: dataToken[propiedad]});
    }
*/

    const respuesta = [];
    respuesta.push({usuario: usuario, nombre: nombre, permisos: permisos});

    return respuesta;
}

export function logout(){
    localStorage.removeItem(llaveToken);
    localStorage.removeItem(llaveExpiracion);
    localStorage.removeItem(llaveUsuario);
    localStorage.removeItem(llaveNombreUsuario);
    localStorage.removeItem(llavePermisos);

    localStorage.removeItem('selectedMenu');

    sessionStorage.clear();
}