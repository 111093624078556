import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getZonas } from '../../helpers/estanques/apiCalls'
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const [openSpinner, setOpenSpinner] = useState(false)
    const [zonas, setZonas] = useState([])

    useEffect(() => {
        let isMounted = true
        setOpenSpinner(true)
        getZonas()
            .then(({ zonas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setZonas(zonas)
                }
            })
        return () => { isMounted = false }
    }, [])

    const handleChangeForm = (name, event) => {
        if (name === "fechaSeguimiento") {
            sessionStorage.setItem('fechaSeguimiento', JSON.stringify(event))
        }
    }

    const onHandleSubmit = e => {
        e.preventDefault()
        setReload(new Date())
    }

    return (
        <div className={classes.root}>
            <form onSubmit={onHandleSubmit}>
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={12}>
                        <Grid container justifycontent="center" spacing={8}>
                        <Grid item>
                                {zonas && zonas.length > 0 &&
                                    <Autocomplete
                                        disableClearable
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={zonas}
                                        getOptionLabel={(option) => `${option.nombre}`}
                                        defaultValue={(() => {
                                            const storedZona = sessionStorage.getItem('zonaSeguimiento');
                                            if (storedZona) {
                                                const parsedZona = JSON.parse(storedZona);
                                                const foundZona = zonas.find(zona => zona.id_zona == parsedZona.id_zona);
                                                if (foundZona) {
                                                    const index = zonas.indexOf(foundZona);
                                                    return zonas[index];
                                                }
                                            }
                                            return null;
                                        })()}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                sessionStorage.setItem('zonaSeguimiento', JSON.stringify(newValue))
                                            } else {
                                                sessionStorage.removeItem('zonaSeguimiento')
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Técnico" placeholder="Seleccione Técnico" />
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="fechaSeguimiento"
                                    label="fechaSeguimiento"
                                    type="date"
                                    defaultValue={moment(sessionStorage.getItem('fechaSeguimiento'), 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                    className={classes.textField}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        handleChangeForm("fechaSeguimiento", newValue);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ marginTop: 8 }}>
                                <ButtonControl
                                    label="Buscar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <Spinner open={openSpinner} />
        </div>
    )
}